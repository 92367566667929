import { Controller } from "@hotwired/stimulus";
import bootstrap from "bootstrap";

export default class extends Controller {


    close() {
        alert('test')
        const modalElement = document.getElementById("add_waiting_list_modal");
        if (modalElement) {
            const modal = bootstrap.Modal.getInstance(modalElement);
            if (modal) {
                modal.hide();
            }
        }
        this.element.remove();
    }
}